import React from 'react';

const Projects = () => {
  return (
    <section id={'projects'}>
      <wrapper>
        Projects
      </wrapper>
    </section>
  )
}

export default Projects;